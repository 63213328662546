import {
  KdtImportBlocksExceptWares,
  KdtImportDeclaration,
  KdtImportPresentedDocumentItem,
  KdtImportWareItem,
  KdtImportWaresAndDocuments,
} from '../../../types/documents/kdt-import';
import { DtImportDeclaration } from '../../../types/documents/dt-import';

export class SetParentDocument {
  static readonly type = '[KdtImport] Set Parent Document';
  constructor(public declaration: DtImportDeclaration | KdtImportDeclaration) {}
}

export class SetKdtImportDocument {
  static readonly type = '[KdtImport] Set KdtImport Document';
  constructor(public declaration: KdtImportDeclaration) {}
}

export class KdtImportUpdateBlock {
  static readonly type = '[KdtImport] Update Block';
  constructor(public block: Partial<KdtImportBlocksExceptWares>) {}
}

export class KdtImportAddPresentedDocument {
  static readonly type = '[KdtImport] Add Presented Document';
  constructor(public presentedDocumentItem: KdtImportPresentedDocumentItem[]) {}
}

export class KdtImportUpdatePresentedDocuments {
  static readonly type = '[KdtImport] Update Presented Documents';
  constructor(public presentedDocuments: KdtImportPresentedDocumentItem[]) {}
}

export class KdtImportReplacePresentedDocuments {
  static readonly type = '[KdtImport] Replace Presented Documents';
  constructor(public presentedDocuments: KdtImportPresentedDocumentItem[]) {}
}

export class KdtImportReplaceWares {
  static readonly type = '[KdtImport] Replace Wares';
  constructor(public wareItems: KdtImportWareItem[]) {}
}

export class KdtImportAddWareItem {
  static readonly type = '[KdtImport] Add Ware Item';
  constructor(public wareItem: KdtImportWareItem) {}
}

export class KdtImportUpdateWareItems {
  static readonly type = '[KdtImport] Update Ware Items';
  constructor(public wareItems: KdtImportWareItem[]) {}
}

export class KdtImportDeleteWareItems {
  static readonly type = '[KdtImport] Delete Ware Item';
  constructor(public documentsAndWares: KdtImportWaresAndDocuments) {}
}

export class KdtImportReplaceWaresAndDocuments {
  static readonly type = '[KdtImport] Replace Blocks';
  constructor(public documentsAndWares: KdtImportWaresAndDocuments) {}
}
